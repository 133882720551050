
.details-field {
  display: block;
}

#buyer-seller-details {
  justify-content: space-evenly;
}

.seller-logo {
  width: calc(var(--font-max) * 2);
  height: calc(var(--font-max) * 2);
  display: block;
}

.with-border {
  border: 1px solid var(--c-gray-4) !important;
}

table {
  tr:nth-child(2) {
    border-top: 1px solid var(--c-gray-2);
  }
}

caption {
  color: var(--c-alt);
  margin-bottom: var(--margin-m);
  margin-top: var(--margin-m);
}
